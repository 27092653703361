import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { graphql } from "gatsby";
import Anchor from "../components/elements/Anchor";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";

const Services = ({ data }) => {
  if (!data) return null;
  const { page } = data;

  return (
    <>
      <Seo page={page} />
      <Layout>
        <div className="container-fluid">
          <div className="image-banner text-center">
            <GatsbyImage
              image={page.data.banner_image.gatsbyImageData}
              className="d-none d-md-block"
              alt={page.data.banner_image.alt || "Services image banner"}
            />
            <GatsbyImage
              image={page.data.banner_image_mobile.gatsbyImageData}
              className="d-block d-md-none"
              alt={page.data.banner_image_mobile.alt || "Services image banner"}
            />
          </div>
          <div className="services-list my-3 my-md-5">
            <div className="row justify-content-center">
              {/* <div className="col-11 col-md-8 offset-md-3 col-xl-9 offset-xl-1 col-xxl-8 offset-xxl-2">
                <ul className="mx-auto">
                  {page.data.services_list.richText.map((item, i) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="nav-item mb-2" key={i}>
                        {item.text}
                      </li>
                    );
                  })}
                </ul>
              </div> */}
              <div className="services-container">
                <div className="row justify-content-between">
                  <div className="col-11 col-lg-5">
                    <h2 className="services-title">
                      {page.data.included_services}
                    </h2>
                    <ul className="mx-auto">
                      {page.data.services_list.richText.map((item, i) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="nav-item mb-2" key={i}>
                            {item.text}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-11 col-lg-5">
                    <h2 className="services-title">
                      {page.data.on_request_services}
                    </h2>
                    <ul className="mx-auto services-list-extra">
                      {page.data.services_list_extra.richText.map((item, i) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="nav-item mb-2" key={i}>
                            {item.text}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="text-center mb-5">
                <Anchor
                  href={page.data.services_button_link.url}
                  className="services-btn text-center"
                >
                  {page.data.services_button}
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query servicesQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicServices {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        banner_image {
          alt
          gatsbyImageData
        }
        banner_image_mobile {
          alt
          gatsbyImageData
        }
        included_services
        on_request_services
        services_list_extra {
          richText
        }
        services_button
        services_button_link {
          url
        }
        services_list {
          richText
        }
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
      }
    }
  }
`;

export default Services;
